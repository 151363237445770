
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    color: String,
  },
  components: {},
  setup(props) {
    const color = ref(props.color);

    const currentColor = computed(() => {
      return color.value ? color.value : "primary";
    });

    return {
      currentColor,
    };
  },
});
