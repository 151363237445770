
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
  },
  components: {},
  setup() {
    const all = ref<boolean>(false);
    const items = [
      {
        logo: "media/svg/brand-logos/aven.svg",
        name: "Brad Simmons",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        logo: "media/svg/brand-logos/leaf.svg",
        name: "Jessie Clarcson",
        tools: "C#, ASP.NET, MS SQL ",
        company: "Agoda",
        skills: "Houses & Hotels",
        progress: "85%",
        color: "primary",
      },
      {
        logo: "media/svg/brand-logos/atica.svg",
        name: "Lebron Wayde",
        tools: "PHP, Laravel, VueJS",
        company: "RoadGee",
        skills: "Transportation",
        progress: "40%",
        color: "success",
      },
      {
        logo: "media/svg/brand-logos/volicity-9.svg",
        name: "Natali Trump",
        tools: "Python, ReactJS",
        company: "The Hill",
        skills: "Insurance",
        progress: "71%",
        color: "info",
      },
      {
        logo: "media/svg/brand-logos/aven.svg",
        name: "Brad Simmons",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
    ];

    return {
      items,
      all,
    };
  },
});
