
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
  },
  components: {
    Dropdown1,
  },
  setup() {
    const items = [
      {
        image: "media/stock/600x400/img-17.jpg",
        title: "Cup & Green",
        description: "Study highway types",
        text: "CoreAd",
        number: "24,900",
        space: "6",
      },
      {
        image: "media/stock/600x400/img-10.jpg",
        title: "Yellow Hearts",
        description: "Study highway types",
        text: "KeenThemes",
        number: "70,380",
        space: "6",
      },
      {
        image: "media/stock/600x400/img-1.jpg",
        title: "Nike & Blue",
        description: "Study highway types",
        text: "Invision Inc.",
        number: "7,200",
        space: "6",
      },
      {
        image: "media/stock/600x400/img-9.jpg",
        title: "Red Boots",
        description: "Study highway types",
        text: "Figma Studio",
        number: "36,450",
        space: "",
      },
    ];

    return {
      items,
    };
  },
});
