
import { defineComponent, ref } from "vue";
import Pagination from "../../../views/components/pagination.vue";
import moment from "moment";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
  },
  components: { Pagination },
  setup() {
    const all = ref<boolean>(false);
    const items = [
      {
        code: "100101",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100102",
        logo: "media/svg/brand-logos/leaf.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "C#, ASP.NET, MS SQL ",
        company: "Agoda",
        skills: "Houses & Hotels",
        progress: "85%",
        color: "primary",
      },
      {
        code: "100103",
        logo: "media/svg/brand-logos/atica.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "PHP, Laravel, VueJS",
        company: "RoadGee",
        skills: "Transportation",
        progress: "40%",
        color: "success",
      },
      {
        code: "100104",
        logo: "media/svg/brand-logos/volicity-9.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "Python, ReactJS",
        company: "The Hill",
        skills: "Insurance",
        progress: "71%",
        color: "info",
      },
      {
        code: "100105",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100106",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100107",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
    ];

    const date = new Date();
    let newDate = date.toDateString();
    newDate.substring(1, 10);

    return {
      items,
      all,
      newDate,
    };
  },
  data: () => ({
    tableItems: "",
    filterItems: "",

    class1: "btn btn-sm btn-light-success fw-boldest",
    class2: "btn btn-sm btn-light-info fw-boldest",
    class3: "btn btn-sm btn-light-warning fw-boldest",
  }),

  methods: {
    handleChangePage() {},
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },
  },
});
