
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
  name: "kt-widget-2",
  props: {
    widgetClasses: String,
  },
  components: {
    Dropdown2,
  },
  setup() {
    const items = [
      {
        image: "media/avatars/150-3.jpg",
        name: "Anne Clarc",
        text: "HTML, CSS, Laravel",
        space: "7",
      },
      {
        image: "media/avatars/150-2.jpg",
        name: "Brad Simmons",
        text: "HTML, JS, ReactJS",
        space: "7",
      },
      {
        image: "media/avatars/150-4.jpg",
        name: "Randy Trent",
        text: "C#, ASP.NET, MS SQL",
        space: "7",
      },
      {
        image: "media/avatars/150-5.jpg",
        name: "Ricky Hunt",
        text: "PHP, Laravel, VueJS",
        space: "7",
      },
      {
        image: "media/avatars/150-6.jpg",
        name: "Jessie Clarcson",
        text: "ReactJS",
        space: "",
      },
    ];

    return {
      items,
    };
  },
});
