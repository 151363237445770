
import { defineComponent, ref } from "vue";
import Pagination from "../../../views/components/pagination.vue";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
  },
  components: { Pagination },
  setup() {
    const all = ref<boolean>(false);
    const items = [
      {
        logo: "media/svg/brand-logos/aven.svg",
        name: "รายการขายปลีก",
        tools: "Retail Sales",
        company: "1,067",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
        percent: "1.5%",
      },
      {
        logo: "media/svg/brand-logos/leaf.svg",
        name: "รายการขายส่ง",
        tools: "Whole Sales",
        company: "24,588",
        skills: "Houses & Hotels",
        progress: "85%",
        color: "primary",
        percent: "2.5%",
      },
      {
        logo: "media/svg/brand-logos/atica.svg",
        name: "รายการซื้อ",
        tools: "Purchases",
        company: "974",
        skills: "Transportation",
        progress: "40%",
        color: "success",
        percent: "3%",
      },
      {
        logo: "media/svg/brand-logos/volicity-9.svg",
        name: "รายการโอนสินค้า",
        tools: "Transfer in/out",
        company: "6,578",
        skills: "Insurance",
        progress: "71%",
        color: "info",
        percent: "4%",
      },
      {
        logo: "media/svg/brand-logos/aven.svg",
        name: "รายการปรับคลังสินค้า",
        tools: "Stock Adjustments",
        company: "79,458",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
        percent: "4.8%",
      },
    ];

    return {
      items,
      all,
    };
  },
  data: () => ({
    tableItems: "",
    filterItems: "",
  }),
  methods: {
    handleChangePage() {},
  },
});
