
import { defineComponent, computed, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    color: String,
  },
  components: {},
  setup(props) {
    const color = ref(props.color);

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["40%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      // xaxis: false,
      xaxis: {
        crosshairs: {
          show: false,
        },
        categories: ["", "", "", "", "", "", ""],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "black",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        crosshairs: {
          show: false,
        },
        labels: {
          show: false,
          // style: {
          //   colors: getCSSVariableValue("--bs-gray-400"),
          //   fontSize: "12px",
          // },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ["#7337EE"],
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + "k";
          },
        },
      },
      colors: [getCSSVariableValue("--bs-primary")],
      grid: {
        borderColor: getCSSVariableValue("--bs-gray-300"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };

    const series1 = [
      {
        name: "Profit",
        data: [30, 40, 30, 25, 40, 45, 30],
      },
    ];

    const series2 = [
      {
        name: "Profit",
        data: [25, 30, 25, 45, 30, 40, 30],
      },
    ];

    const currentColor = computed(() => {
      return color.value ? color.value : "primary";
    });

    return {
      currentColor,
      chartOptions,
      series1,
    };
  },
});
