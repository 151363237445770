
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-widget-4",
  props: {
    widgetClasses: String,
  },
  components: {
    Dropdown3,
  },
  setup() {
    const items = [
      {
        title: "Project Alice",
        description: "Phase 1 development",
        number: "43",
        space: "3",
        icon: "media/icons/duotune/technology/teh008.svg",
        color: "primary",
      },
      {
        title: "HR Confidential",
        description: "Confidential staff documents",
        number: "24",
        space: "3",
        icon: "media/icons/duotune/general/gen044.svg",
        color: "danger",
      },
      {
        title: "Project Rider",
        description: "New frontend admin theme",
        number: "75",
        space: "3",
        icon: "media/icons/duotune/files/fil023.svg",
        color: "success",
      },
      {
        title: "Banner Assets",
        description: "Collection of banner images",
        number: "16",
        space: "3",
        icon: "media/icons/duotune/general/gen006.svg",
        color: "info",
      },
      {
        title: "Icon Assets",
        description: "Collection of SVG icons",
        number: "64",
        space: "6",
        icon: "media/icons/duotune/art/art002.svg",
        color: "warning",
      },
    ];

    return {
      items,
    };
  },
});
