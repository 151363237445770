
import { defineComponent, computed, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    color: String,
  },
  components: {},
  setup(props) {
    const color = ref(props.color);

    const series = [1622, 1322, 1622];

    const chartOptions = {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: ["#E6E6FA"],
        },
        formatter: function (val, opts) {
          let newVal = opts.w.config.series[opts.seriesIndex];
          return newVal;
        },
      },
      fill: {
        type: "gradient",
        colors: ["#E91E63", "#7337EE", "#DCDCDC"],
      },
      colors: ["#E91E63", "#7337EE", "#DCDCDC"],
      labels: ["ยา", "อาหารเสริม", "เวชสำอาง"],
      legend: {
        show: true,

        // formatter: function (seriesName, opts) {
        //   return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]];
        // },

        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },

      stroke: {
        colors: ["#E91E63", "#7337EE", "#DCDCDC"],
        width: 1,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    return {
      chartOptions,
      series,
    };
  },
});
