
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
  },
  components: {},
  setup() {
    const chartOptions = {
      fill: {
        colors: ["#00e396"],
      },
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        colors: ["#00e396"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    };

    const series = [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100, 40, 28, 51],
      },
    ];

    return {
      chartOptions,
      series,
    };
  },
});
