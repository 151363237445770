
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

export default defineComponent({
  name: "kt-widget-3",
  props: {
    widgetClasses: String,
  },
  components: {
    Dropdown1,
  },
  setup() {
    const authors1 = [
      {
        user: { initials: "A", state: "primary" },
        title: "Piper Aerostar",
        subtitle: "iper-aircraft-class.jsp",
        price: "2,040.00$",
      },
      {
        user: { src: "media/avatars/150-3.jpg" },
        title: "Cirrus SR22",
        subtitle: "cirrus-aircraft.jsp",
        price: "280.00$",
      },
      {
        user: { initials: "S", state: "success" },
        title: "Beachcraft Baron",
        subtitle: "beachcraft-class.jsp",
        price: "4,500.00$",
      },
      {
        user: { src: "media/avatars/150-7.jpg" },
        title: "Cessna SF150",
        subtitle: "cessna-aircraft-class.jsp",
        price: "1,050.00$",
      },
    ];

    const authors2 = [
      {
        user: { initials: "T", state: "danger" },
        title: "Roadshow Tesla",
        subtitle: "roadshow-tesla.jsp",
        price: "730.00$",
      },
      {
        user: { initials: "R", state: "info" },
        title: "Landmark RH100",
        subtitle: "landmark-class-jsp",
        price: "850.00$",
      },
      {
        user: { src: "media/avatars/150-5.jpg" },
        title: "Space Jump",
        subtitle: "space-jump-module.jsp",
        price: "2,300.00$",
      },
      {
        user: { src: "media/avatars/150-12.jpg" },
        title: "Flight Carrier",
        subtitle: "flight-module.jsp",
        price: "3,150.00$",
      },
    ];

    const trends = [
      {
        image: "media/svg/brand-logos/plurk.svg",
        title: "Top Authors",
        text: "Mark, Rowling, Esther",
        badge: "+82$",
      },
      {
        image: "media/svg/brand-logos/telegram.svg",
        title: "Popular Authors",
        text: "Randy, Steve, Mike",
        badge: "+280$",
      },
      {
        image: "media/svg/brand-logos/vimeo.svg",
        title: "New Users",
        text: "John, Pat, Jimmy",
        badge: "+4500$",
      },
      {
        image: "media/svg/brand-logos/treva.svg",
        title: "Premium Themes",
        text: "Alex, Kate, Dave",
        badge: "+1500$",
      },
      {
        image: "media/svg/brand-logos/bebo.svg",
        title: "Active Customers",
        text: "Mark, Rowling, Esther",
        badge: "+4500$",
      },
      {
        image: "media/svg/brand-logos/kickstarter.svg",
        title: "Bestseller Theme",
        text: "Disco, Retro, Sports",
        badge: "+4500$",
        space: "",
      },
      {
        image: "media/svg/brand-logos/fox-hub.svg",
        title: "Fox Broker App",
        text: "Finance, Corporate, Apps",
        badge: "+4500$",
      },
    ];

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");

    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-info");

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        stacked: true,
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["12%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        min: -80,
        max: 80,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + " thousands";
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const series = [
      {
        name: "Net Profit",
        data: [40, 50, 65, 70, 50, 30],
      },
      {
        name: "Revenue",
        data: [-30, -40, -55, -60, -40, -20],
      },
    ];

    return {
      authors1,
      authors2,
      trends,
      chartOptions,
      series,
    };
  },
});
