
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTChartWidget2 from "@/components/widgets/charts/Widget2.vue";
import KTChartWidget3 from "@/components/widgets/charts/Widget3.vue";
import KTEngageWidget1 from "@/components/widgets/engage/Widget1.vue";
import KTEngageWidget2 from "@/components/widgets/engage/Widget2.vue";
import KTEngageWidget3 from "@/components/widgets/engage/Widget3.vue";
import KTEngageWidget4 from "@/components/widgets/engage/Widget4.vue";
import KTEngageWidget5 from "@/components/widgets/engage/Widget5.vue";
import KTEngageWidget6 from "@/components/widgets/engage/Widget6.vue";
import KTListWidget1 from "@/components/widgets/lists/Widget1.vue";
import KTListWidget2 from "@/components/widgets/lists/Widget2.vue";
import KTListWidget3 from "@/components/widgets/lists/Widget3.vue";
import KTListWidget4 from "@/components/widgets/lists/Widget4.vue";
import KTSliderWidget1 from "@/components/widgets/sliders/Widget1.vue";
import KTTablesWidget1 from "@/components/widgets/tables/Widget1.vue";
import KTTablesWidget2 from "@/components/widgets/tables/Widget2.vue";
import KTTablesWidget3 from "@/components/widgets/tables/Widget3.vue";
import KTTablesWidget4 from "@/components/widgets/tables/Widget4.vue";
import KTNewCardModal from "@/components/modals/NewCardModal.vue";
import KTCreateAccountModal from "@/components/modals/CreateAccountModal.vue";
import whApi from "@/api/warehouse/";
import { Decode, Encode } from "@/services";

export default defineComponent({
  name: "dashboard",
  components: {
    KTChartWidget1,
    KTChartWidget2,
    KTChartWidget3,
    KTEngageWidget1,
    KTEngageWidget2,
    KTEngageWidget3,
    KTEngageWidget4,
    KTEngageWidget5,
    KTEngageWidget6,
    KTListWidget1,
    KTListWidget2,
    KTListWidget3,
    KTListWidget4,
    KTSliderWidget1,
    KTTablesWidget1,
    KTTablesWidget2,
    KTTablesWidget3,
    KTTablesWidget4,
    KTNewCardModal,
    KTCreateAccountModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboard", ["Dashboards"]);
    });
    document.title = "bem-document-demo Warehouses";
  },
});
