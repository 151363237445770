import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-xl-8" }
const _hoisted_2 = { class: "col-xxl-6" }
const _hoisted_3 = { class: "row g-xl-8" }
const _hoisted_4 = { class: "col-xl-6" }
const _hoisted_5 = { class: "col-xl-6" }
const _hoisted_6 = { class: "row g-xl-8" }
const _hoisted_7 = { class: "col-xl-6" }
const _hoisted_8 = { class: "col-xl-6" }
const _hoisted_9 = { class: "col-xxl-6" }
const _hoisted_10 = { class: "row g-xl-8" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "row g-xl-8" }
const _hoisted_13 = { class: "col-xxl-6" }
const _hoisted_14 = { class: "row g-xl-8" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "col-xxl-6" }
const _hoisted_17 = { class: "row g-xl-8" }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { class: "row g-xl-8" }
const _hoisted_20 = { class: "col-xxl-4" }
const _hoisted_21 = { class: "row g-xl-8" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "col-xxl-8" }
const _hoisted_24 = { class: "row g-xl-8" }
const _hoisted_25 = { class: "col" }
const _hoisted_26 = { class: "row g-xl-8" }
const _hoisted_27 = { class: "col-xxl-4" }
const _hoisted_28 = { class: "row g-xl-8" }
const _hoisted_29 = { class: "col" }
const _hoisted_30 = { class: "col-xxl-8" }
const _hoisted_31 = { class: "row g-xl-8" }
const _hoisted_32 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTEngageWidget3 = _resolveComponent("KTEngageWidget3")!
  const _component_KTEngageWidget4 = _resolveComponent("KTEngageWidget4")!
  const _component_KTEngageWidget5 = _resolveComponent("KTEngageWidget5")!
  const _component_KTEngageWidget6 = _resolveComponent("KTEngageWidget6")!
  const _component_KTChartWidget1 = _resolveComponent("KTChartWidget1")!
  const _component_KTChartWidget2 = _resolveComponent("KTChartWidget2")!
  const _component_KTChartWidget3 = _resolveComponent("KTChartWidget3")!
  const _component_KTTablesWidget2 = _resolveComponent("KTTablesWidget2")!
  const _component_KTTablesWidget3 = _resolveComponent("KTTablesWidget3")!
  const _component_KTEngageWidget2 = _resolveComponent("KTEngageWidget2")!
  const _component_KTTablesWidget4 = _resolveComponent("KTTablesWidget4")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_KTEngageWidget3, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_KTEngageWidget4, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_KTEngageWidget5, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_KTEngageWidget6, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_KTChartWidget1, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_KTChartWidget2, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_KTChartWidget3, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_KTTablesWidget2, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_KTTablesWidget3, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_KTEngageWidget2, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createVNode(_component_KTTablesWidget4, { "widget-classes": "card-xl-stretch mb-5 mb-xl-8" })
          ])
        ])
      ])
    ])
  ], 64))
}