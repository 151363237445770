
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

export default defineComponent({
  name: "kt-widget-2",
  props: {
    widgetClasses: String,
  },
  components: { Dropdown1 },
  setup() {
    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["40%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      xaxis: {
        // crosshairs: {
        //   show: false,
        // },
        categories: [
          "Med",
          "Cosmetics",
          "Vitamins",
          "Skincares",
          "Mom",
          "Baths",
          "Equipment",
          // "Aug",
          // "Sep",
          // "Oct",
          // "Nov",
          // "Dec",
        ],
        // axisBorder: {
        //   show: false,
        // },
        // axisTicks: {
        //   show: false,
        // },
        labels: {
          style: {
            colors: "black",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        // crosshairs: {
        //   show: false,
        // },
        labels: {
          style: {
            colors: getCSSVariableValue("--bs-gray-400"),
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ["#7337EE", "#DCDCDC"],
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + "k";
          },
        },
      },
      colors: [getCSSVariableValue("--bs-primary")],
      grid: {
        borderColor: getCSSVariableValue("--bs-gray-300"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    // const series1 = [
    //   {
    //     name: "Profit",
    //     data: [30, 40, 30, 25, 40, 45, 30],
    //   },
    // ];

    const series1 = [
      {
        name: "Net Profit",
        data: [30, 40, 30, 25, 40, 45, 30],
      },
      {
        name: "Last year",
        data: [35, 30, 40, 20, 50, 55, 25],
      },
    ];

    return {
      chartOptions,
      series1,
    };
  },
});
